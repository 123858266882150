import React, { createContext, useEffect, useReducer, useState } from 'react';
import jwtDecode from 'jwt-decode';

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT, SNACKBAR_ERROR } from '../store/actions';
import axios from 'axios';
import accountReducer from '../store/accountReducer';
import Loader from '../component/Loader/Loader';
import API from '../utils/api';
import { useDispatch } from 'react-redux';

const initialState = {
    isLoggedIn: false,
    isInitialised: false,
    user: null,
};

const verifyToken = (Token) => {
    if (!Token) {
        return false;
    }

    const decoded = jwtDecode(Token);
    return decoded.exp > Date.now() / 1000;
};

const setSession = (Token) => {
    if (Token) {
        localStorage.setItem('Token', Token);
        axios.defaults.headers.common.Authorization = `Bearer ${Token}`;
    } else {
        localStorage.removeItem('Token');
        delete axios.defaults.headers.common.Authorization;
    }
};

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => { },
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const dispatchSB = useDispatch();

    const login = async (name, password) => {
        const response = await API.login(name, password);
        console.log(response);
        const { data } = response.data;
        if (!data) {
            dispatchSB({ type: SNACKBAR_ERROR, payload: 'Login failed' });
            return;
        }
        const { token } = data;
        setSession(token);
        const userResponse = await API.get_admin_me();
        const user = userResponse.data;
        const menuResponse = await API.get_menu_item(user.data.role);
        const menuItem = menuResponse.data;
        dispatch({
            type: LOGIN,
            payload: {
                user,
                menuItem
            },
        });
    };

    const [refreshing, setRefreshing] = useState(false);
    const callToken = () => {
        setRefreshing(ing => {
            if (!ing) {
                runToken()
                return true;
            }
            return false
        })
    };

    const runToken = async () => {
        try {
            //const Token = window.localStorage.getItem('Token');
            //const response = await API.refreshToken(Token);
            //const { data } = response.data;
            // if (!data) {
            //     return;
            // }
            // const { token } = data;
            //setSession(Token);
        } catch (e) {
        } finally {
            setRefreshing(false);
        }
    };

    const tokenExpired = () => {
        const Token = window.localStorage.getItem('Token');
        if (Token) API.revokeToken(Token);
        dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
                isLoggedIn: false,
                user: null,
                menuItem: null
            },
        });
    };

    const logout = () => {
        const Token = window.localStorage.getItem('Token');
        API.revokeToken(Token);
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const Token = window.localStorage.getItem('Token');
                if (verifyToken(Token)) {
                    setSession(Token);
                    const response = await API.get_admin_me();
                    const user = response.data;
                    const menuResponse = await API.get_menu_item(user.data.role);
                    const menuItem = menuResponse.data;
                    dispatch({
                        type: ACCOUNT_INITIALISE,
                        payload: {
                            isLoggedIn: true,
                            user,
                            menuItem
                        },
                    });
                } else {
                    tokenExpired();
                }
            } catch (err) {
                tokenExpired();
            }

            axios.interceptors.response.use(
                response => {
                    if (!API.skipRefresh(response.config.url)) {
                        callToken();
                        return response;
                    }
                    return response;
                },
                error => {
                    if (error.response.config.url.split('/').splice(-1)[0] === 'login') {
                        dispatchSB({ ...SNACKBAR_ERROR, message: "Invalid email or password." });
                        return error.response;
                    } else if (error.response.status === 401 || error.response.data.error === 'jwt expired') {
                        dispatchSB({ ...SNACKBAR_ERROR, message: "Your session has expired, please login again." });
                        tokenExpired();
                        return error.response;
                    }

                    throw error;
                }
            );
        };

        init();
    }, []);

    if (!state.isInitialised) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
