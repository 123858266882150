import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import config from './config';
import useAuth from './hooks/useAuth';

import Loader from './component/Loader/Loader';
import NavMotion from './layout/NavMotion';
import MainLayout from './layout/MainLayout';
import GuestGuard from './component/Auth/GuestGuard';
import AuthGuard from './component/Auth/AuthGuard';
import MinimalLayout from './layout/MinimalLayout';

const NotFound = lazy(() => import('./views/Pages/Error/Error1')); //404
const AuthLogin = lazy(() => import('./views/Login'));
const _BuyerAccount = lazy(() => import('./views/List/_BuyerAccount'));
const _Order = lazy(() => import('./views/List/_Order'));
const _Bot = lazy(()=> import('./views/List/_Bot'));
const _Job = lazy(()=> import('./views/List/_Job'));

const _Proxy = lazy(() => import('./views/List/_Proxy'))
const _TestMode = lazy(()=> import('./views/List/_TestMode'));
const _MerchantType = lazy(()=> import('./views/List/_MerchantType'));
const _VirtualMachine = lazy(()=> import('./views/List/_VirtualMachine'));
const _DefaultAnswer = lazy(()=> import('./views/List/_DefaultAnswers'))
const _SingleDefaultAnswer = lazy(() => import('./views/List/_DefaultAnswer/list'))
const _History = lazy(()=> import('./views/List/_History'))

const Profile = lazy(() => import('./views/Settings/Profile'));
const AdminUserList = lazy(() => import('./views/Settings/AdminUserList'));
const _Config = lazy(() => import('./views/Settings/Config'));
const _SystemHealth = lazy(() => import('./views/Settings/SystemHealth'));

const ComingSoon = lazy(() => import('./views/Pages/Comingsoon'));

const Routes = () => {
    const homeUrl = config.home_url;
    const { menuItem } = useAuth();
    const location = useLocation();
    const [visibleList, setVisibleList] = useState([homeUrl]);

    useEffect(() => {
        init();
    }, [menuItem]);

    const init = () => {
        console.log(menuItem);
        let newVisibleList = [homeUrl];
        for (let key in menuItem) {
            if (menuItem[key].visible && menuItem[key].url) {
                newVisibleList.push(menuItem[key].url);
            }
        }
        setVisibleList(newVisibleList);
    };

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Redirect exact from="/" to={homeUrl} />
                    <Route path={['/login']}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <GuestGuard>
                                        <Route path="/login" component={AuthLogin} />
                                    </GuestGuard>
                                </NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route
                        path={visibleList}
                    >
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard>
                                        <Route path="/list/order" component={_Order} />
                                        <Route path="/list/buyer_account" component={_BuyerAccount} />
                                        <Route path="/list/bot" component={_Bot} />
                                        <Route path="/list/job" component={_Job} />
                                        <Route path="/list/test_mode" component={_TestMode} />
                                        <Route path="/list/default_answers" component={_DefaultAnswer} />
                                        <Route path="/list/merchant_type" component={_MerchantType} />
                                        <Route path="/list/proxy" component={_Proxy} />
                                        <Route path="/list/virtual_machine" component={_VirtualMachine} />
                                        <Route path="/list/default_answer/list" component={_SingleDefaultAnswer} />
                                        <Route path="/list/history" component={_History} />

                                        <Route path="/settings/profile" component={Profile} />
                                        <Route path="/settings/admins" component={AdminUserList} />
                                        <Route path="/settings/config" component={_Config} />
                                        <Route path="/settings/system_health" component={_SystemHealth} />
                                    </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                    <Route
                        path={'/'}
                    >
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard>
                                        <Route exact={true} component={NotFound} />
                                    </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
