const Main = () => {
    console.log(process.env);
    return {
        version: '4.7.0',
        basename: '/',
        whitelist: ['10.0.2.130', '127.0.0.1'],
        bns_api_base: process.env.REACT_APP_API,

        home_menu_item_id: 'list_bns_order',
        home_url: '/list/order/',

        theme: 'light',
        rtlLayout: false,
        i18n: 'en',
    };
};

export default Main();
